//extra-info
.extra-info {
  height: 498.77px;
  background-color: #162032;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .button-filled {
    width: 310px;
    height: 71.89px;
    background-color: #162032;
    border-radius: 45px;
    border: 1.5px solid #d1eaf1;
    color: #d1eaf1;
    font-size: 2.8rem;
    margin-top: 15px;
  }
}

.extra-info-content {
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 4rem;
    width: 60rem;
    color: #d1eaf1;
    text-align: center;
    font-size: 2.6rem;
  }
}

.extra-info-image {
  width: 291px;
  margin-left: 5rem;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .extra-info-content {
    margin-right: 0;
   
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .extra-info {
    height: 427.77px;
    padding-left: 10rem;

    .button-filled {
      width: 200px;
      height: 60px;
    }

  }

  .extra-info-image {
    width: 253px;
    margin-left: 4rem;
  }

  .extra-info-content {
    margin-right: 0;
    p {
      width: 45rem;
      font-size: 2.3rem;
    }
  }
}

@media (max-width: 600px) {
  .extra-info {
    height: 666.23px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .button-filled {
      width: 167.91px;
      height: 47.88px;
      margin-top: 0px;
    }  
  }

  .extra-info-image {
    width: 227px;
    margin-left: 5rem;
  }

  .extra-info-content {
    margin-right: 0rem;
    p {
      width: 400px;
      font-size: 2.5rem;
      margin-bottom: 5rem;
    }
  }
}
