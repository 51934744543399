.board {
  font-family: "Sen", sans-serif;

  .startup-logo{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
}

.name-text{
  color: #162032;
  font-size: 3rem;
  font-weight: 700;
}

.url-text{
  color: #4EBAB8;
  font-size: 1.8rem;
  cursor: pointer;
}

.category-text{
  color: #162032;
  font-size: 2.1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  cursor: pointer;
}

.subcategory-text{
  color: #4EBAB8;
  font-size: 2.1rem;
  font-weight: 700;
  margin-top: 0.2rem;
  cursor: pointer;
}

.description-text{
  color: #162032;
  font-size: 2.3rem;
}

.startup{
  padding-top: 20px;
  padding-bottom: 5px;
  border-top: 1px solid #162032;
  margin-top: 16px;
  min-height: 125px;
}

.startup-info{
  padding-left: 10px;
}

.filters-selected{
  background-color: #D1EAF1;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-selected-box {
  height: 3rem;
  overflow: hidden;
  font-size: 1.8rem;
  color: #162032;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.filters-center{
  margin-left: auto;
  margin-right: auto;
  width: 50%!important;
  font-size: 1.3rem;
}

.filters-title{
  color: #162032;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block!important;
  margin-left: auto;
  margin-right: auto;
}

.startups-board-title{
  background-color: #162032;
}

.startups-board-title-text{
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  font-size: 7.5rem;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 10rem;
  font-family: "Sen", sans-serif;
  font-weight: 800;
}

.read-more{
  cursor: pointer;
  font-weight: bold;
}

.filter-selected{
  border-width: 1px;
  border-style: solid;
  border-color: #162032;
  white-space: nowrap;
  text-align: center;
  padding: 3px;
  margin-right: 10px;
}

.filters-bar{
  cursor: pointer;
  color: #162032;
  font-size: 1.8rem;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.startup-button{
  border-radius: 20px;
  background-color: #162032;
  color: #FFFFFF;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  font-size: 1.8rem;
  width: 100%;
  min-width: 130px;
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .startup-button{
    font-size: 1rem;
    min-width: 80px!important;
  }
}

@media screen and (max-width: 720px) {
  .startup-button{
    font-size: 1rem;
    min-width: 80px!important;
  }

  .filter-selected-box {
    font-size: 1.5rem;
  }
}

.filters-box{
  padding: 2rem;
}

.filter{
  background-color: #4EBAB8;
  border: 0px;
  border-radius: 6px;
  width: 18rem;
  color: #162032;
  padding: 10px;
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.filter::placeholder {
  color: #162032;
  opacity: 1;
  font-weight: bold;
}

.filter::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.circular-progress {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.MuiCircularProgress-circleDisableShrink {
  color: #162032;
}

@media screen and (min-width: 765px) and (max-width: 1346px) {
  .startups-board-title-text{
    font-size: 6.5rem;
    line-height: 8.5rem;
    padding-top: 5%;
  }
}

@media screen and (min-width: 765px) and (max-width: 960px) {
  .filters-title{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 764px) {
  .startups-board-title-text{
    font-size: 7rem;
    padding-top: 0%;
  }

  .filters-title{
    font-size: 1.5rem;
  }
}

.end-text{
  text-align: center;
  padding: 50px;
  font-size: 2rem;
  cursor: pointer;
}

.advert_title{
  font-size: 7.5rem;
  color: #FFFFFF;
  line-height: 10rem;
  font-family: "Sen", sans-serif;
  font-weight: 800;
  padding-left: 3rem;
}

.scroll-top-button {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 100px;
  background-color: #4EBAB8;
  position: fixed;
  bottom: 30px;
  right: 10rem;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.up-arrow-icon {
  font-size: 6rem !important;
}

.hide_arrow {
  padding-right: 5rem;
}

.no-scroll {
  animation: hide_button 0.5s ease forwards;
}
@keyframes hide_button {
  0%{
      transform: translateY(-10px);
  }
  100%{
      transform: translateY(200%);
  }
}

.active-scroll {
  animation: show_button 0.5s ease forwards;
}
@keyframes show_button {
  0%{
      transform: translateY(100%);
  }
  100%{
      transform: translateY(-10px);
  }
}

@media screen and (max-width: 1300px) {
  .hide_arrow{
    display:none !important;
  }

  .advert_title{
    font-size: 6.5rem;
  }
}

.subscribe_box.advert_box {
  height: 50rem;
  border-top: 0px solid #162032;
  margin-top: 10px!important;
}

@media screen and (max-width: 900px) {
  .subscribe_box.advert_box {
    height: 38rem;
    margin-top: 0px !important;
    padding-top: 0px;
  }
}

.advert_separation {
  border-top: 1px solid #162032;
  height: 10px;
}

.go_up{
  font-weight: bold;
}
