.category_letter {
  padding: 10px;
  color: #162032;
  font-size: 4rem;
  cursor: pointer;
}

.category_letters {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.categories-board {

  .small-intro-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .small-intro {
    width: 90rem;
    color: #162032;
    font-size: 3rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: -3rem;
  }
}

.category_element {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.category-board {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.category_advert {
  background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
  opacity: 1;
}

@media (min-width:769px) and (max-width: 1590px) {
  .categories-board {
    .section-header {
      font-size: 5.7rem;
      line-height: 8rem;
    }
  }
}

@media (max-width:768px) {
  .categories-board {
    .section-header {
      font-size: 5rem;
      line-height: 7rem;
      padding-top: 7rem;
    }
  }
}