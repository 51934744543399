html {
  //if screen width is 1200px, 0.884vw roughly equals 10px;
  font-size: 0.833vw;
}

html,
body {
  padding: 0;
  padding-right: 0 !important;
  margin: 0;
  max-width: 100%;
  min-width: 550px;
  font-family: "Sen", sans-serif;
  overflow-x: hidden;
}

body {
  padding-top: 12.6rem;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  font-family: inherit;
  border: 0px;
  cursor: pointer;
}

input {
  font-family: inherit;
}

input:focus::placeholder {
  font-family: inherit;
  color: transparent;
}

fieldset {
  border: 0px;
}

/* global modules */

.button-filled {
  border: 0px;
  border-radius: 18px;
  font-weight: bold;
}

.submit-button {
  border: 0px;
  width: 39.6rem;
  height: 5.13rem;
  background-color: #162032;
  color: #4ebab8;
  font-size: 2rem;
}

.section-header {
  width: 100%;
  height: 35rem;
  background-color: #162032;
  position: relative;
}

.section-title {
  font-size: 5rem;
  font-family: "Sen", sans-serif;
  font-weight: 800;
}

.custom-label {
  color: #162032 !important;
  font-size: 2rem !important;
}

.circle {
  width: 50rem;
  height: 25rem;
  background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  z-index: 1;
}

.framed-text {
  position: relative;
  background-color: #162032;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.triangle {
  clip-path: polygon(50% 0, 20% 100%, 50% 100%);
  position: absolute;
}

.shape-right {
  clip-path: polygon(50% 0, 20% 100%, 50% 100%);
  right: -54rem;
  background-color: #7ac6c5;
}

.shape-left {
  clip-path: polygon(50% 0, 80% 0, 50% 100%);
  left: -54rem;
  background-color: #4a86c6;
}

.capitalized-name {
  text-transform: capitalize;
}

.legal-link {
  color: #4ebab8;
  cursor: pointer;
}

.MuiFormControl-root {
  margin-bottom: 1.8rem !important;
  width: "50rem";
}

.css-1wc848c-MuiFormHelperText-root,
.css-v7esy.Mui-error {
  max-width: 38rem;
  font-size: 1.8rem !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  line-height: unset;
}

.css-1i9p219-MuiPaper-root-MuiAlert-root {
  font-size: 1.8rem;
}

@media (min-width: 1201px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .section-title {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 6px;
  }
}

.display-none {
  display: none;
}