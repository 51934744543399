footer {
    width: 100%;
    height: 350px;
    background: #162032;
    color: #d1eaf1;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.proptechpedia-imagotype {
    width: 21rem;
}

.main-nav {
    width: 85rem;
    height: 3.5rem;
    list-style-type: none;
    color: #4ebab8;
    padding: 0px;
    margin-bottom: 10rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .nav-item {
        cursor: pointer;
        font-size: 2rem;
        margin-top: 0.6rem;
        margin-right: 3.5rem;
        margin-bottom: 1rem;
    }

    .nav-items-box {
        display: flex;
        flex-direction: column;
    }
}

.footer-text {
    width: 84.6rem;
    height: 137.29px;
    color: #d1eaf1;
    font-size: 1.5rem;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bottom-box {
    width: 80rem;
    font-size: 1.5rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
        margin: 0px;
        line-height: 1.8;
    }
}

.media-nav {
    list-style-type: none;
    display: flex;
    flex-direction: row;

    li {
        cursor: pointer;
        margin-right: 0.8rem;
        margin-left: 0.8rem;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    footer {
        height: 366.06px;
    }
}

@media (max-width: 768px) {
    footer {
        flex-direction: column;
        align-items: center;
        align-content: flex-end;
    }

    .footer-content {
        margin-top: 0px;
    }

    .proptechpedia-imagotype {
        width: 219.63px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    footer {
        height: 480.43px;
    }

    .footer-content {
        justify-content: center;
        align-items: center;
    }

    .main-nav {
        width: 611.67px;
        height: 43.83px;
    }

    .bottom-box {
        width: 611.67px;
    }

    .footer-text {
        width: 611.67px;
    }
}

@media (max-width: 600px) {
    footer {
        height: 480.43px;
    }

    .main-nav {
        width: 492.89px;
        height: 35.5px;

        .nav-item {
            margin-right: 2.5rem;
        }
    }

    .bottom-box {
        width: 487px;
    }

    .footer-text {
        width: 487px;
    }

    .footer-content {
        width: 500px;
    }
}
