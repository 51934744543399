.legal-document {
    min-height: 1000px;
    text-align: justify;
    font-size: 2rem;
    padding-top: 8rem;
    padding-right: 35rem;
    padding-bottom: 8rem;
    padding-left: 35rem;
    color: #162032;

    p {
        margin-top: 0px;
        font-size: 2rem;
        margin-bottom: 4rem;
    }

    ol {
        padding-left: 0px;
    }

    .section-title {
        margin-bottom: 7rem;
    }
}

.condition {
    list-style: none;
}

.cookies-info-list {
    margin-bottom: 4rem;
}

.highlighted-title {
    font-size: 2rem;
    color: #4ebab8;
}

.bold-title {
    font-size: 2rem;
    margin-bottom: 0px;
}

@media (max-width: 1400px) {
    .legal-document {
        padding-right: 25rem;
        padding-left: 25rem;
    }
}

@media (max-width: 768px) {
    .legal-document {
        font-size: 3rem;
        padding-right: 15rem;
        padding-left: 15rem;
        
        p {
            font-size: 3rem;
        }
    }

    .highlighted-title {
        font-size: 3rem;
    }
    
    .bold-title {
        font-size: 3rem;
    }
}
