.category-preview {
    position: relative;
    width: 191.89px;
    height: 191.89px;
    padding: 0;
    margin-left: 20px;
    margin-right: 20px;
}

.category-wrapper {
    background-color: #162032d5;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-name {
    cursor: pointer;
    width: 191.89px;
    text-align: center;
    color: #d1eaf1;
    font-size: 2.5rem;
    overflow-wrap: break-word;
    font-family: "Sen", sans-serif;
    font-weight: 700;
}

.category-image,
.category-wrapper {
    position: absolute;
    width: 191.89px;
    height: 191.89px;
    transition: opacity 0.3s ease-in-out;
}

.category_element {
    .category-image,
    .category-wrapper,
    .category-preview {
        width: 37.5rem;
        height: 37.5rem;
    }

    .category-name {
        font-size: 4.5rem;
        width: fit-content;
        max-width: 36rem;
    }

    .advert_title {
      font-size: 3.5rem;
      line-height: 5rem;
      cursor: pointer;
      padding-left: 1.5rem;
      padding-right: 10px;
    }
}

.category-wrapper:hover {
    opacity: 1;
}

.category-board-element {
    background-color: aqua;
}

@media (max-width: 1590px) {
    .category_element {
        .category-image,
        .category-wrapper,
        .category-preview {
            width: 28rem;
            height: 28rem;
        }

        .advert_title {
            font-size: 3rem;
            line-height: 4rem;
        }

        .category-name {
            font-size: 3.8rem;
            width: fit-content;
            max-width: 27rem;
        }
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .category-preview {
        margin-left: 7.5px;
        margin-right: 7.5px;
    }

    .category-image,
    .category-wrapper,
    .category-preview {
        width: 15.9rem;
        height: 15.9rem;
    }

    .category_element {
        .category-name {
            font-size: 3.5rem;
        }

        .advert_title {
            font-size: 3rem;
            line-height: 4rem;
        }
    }
}

@media (max-width: 768px) {
    .category-wrapper {
        opacity: 1;
    }

    .category_element {
        .category-image,
        .category-wrapper,
        .category-preview {
            width: 31rem;
            height: 31rem;
        }

        .category-name {
            font-size: 3.8rem;
            max-width: 29;
        }
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .category-preview {
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }

    .category-name {
        font-size: 4.5rem;
    }
}

@media (max-width: 600px) {
    .category-preview {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .category-name {
        font-size: 5rem;
    }

    .category-image,
    .category-wrapper,
    .category-preview {
        width: 228px;
        height: 228px;
    }
}
