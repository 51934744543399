.featured-companies-wrapper {
    padding-top: 35px;
    padding-bottom: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-title {
        color: #162032;
        margin-bottom: 10rem;
    }

    .action-area {
        position: relative;
    }
}

.featured-company-logo {
    width: 100%;
    height: 100%;
}

.featured-company-arrow {
    width: 13rem;
    height: 6rem;
}

.container {
    position: relative;
    z-index: 2;
}

.circle {
    left: -22.5rem;
    bottom: -26rem;
    font-size: 3.5rem;
    text-align: center;
    color: #162032;
    cursor: pointer;
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        width: 40rem;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .featured-companies-wrapper {
        padding-top: 0px;
        padding-bottom: 28rem;
    }

    .circle {
        width: 45rem;
        height: 23rem;
        font-size: 3rem;
        text-align: center;
        left: -22rem;
        bottom: -25rem;
    }
}

@media (max-width: 768px) {
    .featured-companies-wrapper {
        padding-bottom: 40rem;

        .section-title {
            color: #162032;
            margin-bottom: 6rem;
            font-size: 6rem;
        }
    }

    .circle {
        width: 60rem;
        height: 30rem;
        bottom: -35rem;
    }
}