.profile-header-wrapper {
    width: 100%;
    height: 50.8rem;
    margin-bottom: 3rem;
    background-color: #4ebab8;
    position: relative;
    z-index: 1;

    .button-filled {
        font-family: "Sen";
        font-weight: 100;
        width: 63rem;
        height: 5rem;
        font-size: 2.1rem;
        border-radius: 30px;
        background-color: #162032;
        color: #4ebab8;
    }

}

.startup-profile-header {
    width: fit-content;
    height: 30.63rem;
    margin-top: 18rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .startup-logo {
        width: 30.63rem;
        height: 30.63rem;
        border: 1.5px solid white;
        background-color: #ffffff;
        margin-right: 23px;
    }
}

.startup-profile-info {
    width: auto;
    height: 306.38px;
    padding-top: 1.5rem;
    .name {
        margin-top: 0px;
        color: #ffffff;
        font-size: 7.2rem;
        font-family: "Sen", sans-serif;
        font-weight: 800;
        margin-bottom: 1rem;
        line-height: 7rem;
    }
    .startup-category-wrapper {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        margin-left: 5px;
    }
    .category,
    .subcategory {
        color: #162032;
        font-size: 3.8rem;
        margin: 0px;
        display: inline-block;
    }
    .category {
        font-family: "Sen";
        font-weight: 700;
    }
    .button-filled {
        padding-left: 3rem;
        padding-right: 3rem;
        width: auto;
    }
}

.startup-data {
    margin-right: 3rem;
    border-right: 1.5px solid #4ebab8;
    margin-top: 30px;

    .button-filled {
        margin-top: 30px;
        margin-right: 40px;
    }

    .description {
        font-size: 2.8rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
    }

    .description-wrapper {
        padding-top: 2rem;
        border-bottom: 1.5px solid #4ebab8;
    }

    .founders-section {
        border-bottom: 1.5px solid #4ebab8;
        min-height: 14rem;
    }

    .foundation-year {
        font-size: 2.8rem;
    }
}

.investment-round-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3rem;

    .submit-button {
        width: 31.83rem;
        color: #162032;
        background-color: #4ebab8;
    }

    .data-wrapper {
        border: 2px solid #4ebab8;
        margin-bottom: 15px;
    }

    .data-header {
        font-size: 1.8rem;
    }

    .data-wrapper.row {
        width: 31.7rem;
        min-height: 4.03rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .data {
            width: 15rem;
            height: 100%;
            min-height: 4.03rem;
            text-align: center;
            color: #4ebab8;
            font-size: 1.8rem;
            border-left: 2px solid #4ebab8;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .data-header {
            width: 18rem;
            text-align: left;
        }
    }

    .data-wrapper.column {
        width: 32.05rem;

        .data {
            border-top: 2px solid #4ebab8;
            width: 100%;
            height: fit-content;
            min-height: 15.2rem;
            font-size: 2rem;
            color: #4ebab8;
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .main-investor {
            font-size: 2.5rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .data-header {
            text-align: center;
            height: 3.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.data-header {
    color: #162032;
    font-size: 2rem;
    padding-left: 5px;
    padding-bottom: 3px;
}

.similar-startups {
    .action-button {
        background-color: #ffffff;
        color: #4ebab8;
        font-size: 2rem;
        border: 1px solid #4ebab8;
        border-radius: 10px;
    }
}

.founders-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .small-title {
        font-size: 3rem;
        margin-bottom: 5px;
    }
}

.founders-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.founder {
    width: 15.5rem;
    .name {
        color: #4ebab8;
        font-size: 2.5rem;
        margin-bottom: 5px;
    }
    .position {
        color: #162032;
        font-size: 2.5rem;
        margin-top: 0px;
    }
}

.small-title {
    color: #162032;
    font-size: 2.3rem;
    font-family: "Sen", sans-serif;
    font-weight: 700;
    text-align: center;
}

.startup-navigation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.breadcrumbs {
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    color: #162032;
    font-size: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.crumb {
    margin-left: 5px;
    margin-right: 5px;
}

.clickable-crumb {
    cursor: pointer;
}

.similar-companies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .startup-navigation-button {
        width: 57.2rem;
        margin-top: 6.5rem;
        margin-bottom: 10rem;
    }

    .section-title {
        color: #162032;
        margin-bottom: 2rem;
    }
}

.startup-navigation-button {
    width: 30rem;
    height: 8.49rem;
    border-radius: 50px;
    background-color: #ffffff;
    border: 1.5px solid #4ebab8;
    color: #4ebab8;
    font-size: 3rem;
    margin: 2rem 1rem 0rem 1rem;
}

.disabled-button {
    color: #5d8584;
    border-color: #5d8584;
    cursor: not-allowed;
}

@media (min-width: 1201px) and (max-width: 1350px) {
    .startup-profile-header {
        left: 15%;
    }

    .startup-data {
        margin-left: 13%;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .startup-profile-header {
        left: 10%;
    }

    .startup-navigation-small {
        width: 20rem;
        font-size: 2rem;
        height: 5rem;
    }

    .startup-data {
        margin-left: 8%;
        .description {
            font-size: 2rem;
        }

        .foundation-year {
            font-size: 2rem;
        }
    }

    .small-title {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .startup-profile-body {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .startup-data {
        border: 0px;
        margin-left: 10rem;
        margin-right: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button-filled {
            margin-top: 30px;
            margin-right: 0px;
        }

        .description-wrapper {
            width: 100%;
        }
    }

    .founders-section {
        width: 100%;
        .small-title {
            font-size: 4rem;
        }
    }

    .founder {
        width: 15.5rem;
        .name {
            color: #4ebab8;
            font-size: 3rem;
            margin-bottom: 5px;
        }
        .position {
            color: #162032;
            font-size: 3rem;
            margin-top: 0px;
        }
    }

    .small-title {
        font-size: 3rem;
    }

    .investment-round-data {
        .submit-button {
            width: 45rem;
            font-size: 2.5rem;
            color: #162032;
            background-color: #4ebab8;
        }

        .data-header {
            font-size: 2rem;
            padding-bottom: 0px;
        }

        .data-wrapper.row {
            width: 45rem;

            .data {
                width: 21.4rem;
                font-size: 2rem;
            }

            .data-header {
                width: 23.6rem;
                text-align: left;
            }
        }

        .data-wrapper.column {
            width: 45rem;

            .data {
                font-size: 2rem;
            }

            .main-investor {
                font-size: 3rem;
            }
        }
    }
}
