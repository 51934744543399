.team {
    .section-title {
        width: 80rem;
        color: #ffffff;
        font-size: 6.5rem;
        margin: 0px;
        position: absolute;
        top: 15%;
        left: 20%;
    }

    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tflabs-introduction {
    width: 155rem;
    height: fit-content;
    text-align: center;
    font-size: 3.8rem;
    margin-top: 7rem;
    margin-bottom: 0px;
}

.tflabs-logotype {
    width: 75rem;
    height: 8rem;
    margin-top: 6rem;
}

.team-wrapper {
    width: 100%;
    margin-top: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.team-member {
    width: 23rem;
    height: 30rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.team-member-photo {
    background-color: #162032;
    width: 20rem;
    height: 20rem;
}

.team-member-name {
    width: fit-content;
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 3.5rem;
    color: #162032;
}

@media (max-width:1570px) {
    .tflabs-introduction {
        width: 115rem;
        font-size: 3.5rem;
    }

    .tflabs-logotype {
        width: 60rem;
        height: 6.5rem;
    }
}

@media (max-width:769px) {
    .tflabs-introduction {
        width: 85rem;
    }
}

@media (min-width:600px) and (max-width: 768px) {
    .team {
        .section-title {
            left: 15%;
        }
    }
}

@media (max-width:600px) {
    .team {
        .section-title {
            left: 10%;
        }
    }
}