.registration {
    .section-header {
        .section-title {
            width: 110rem;
            color: #ffffff;
            font-size: 6rem;
            margin: 0px;
            position: absolute;
            top: 15%;
            left: 20%;
        }
    }

    .circle {
        width: 53rem;
        height: 27rem;
        bottom: 5rem;
        left: 38rem;
    }

    .triangle {
        width: 100rem;
    }

    .shape-right {
        height: 90rem;
        top: -170px;
    }

    .shape-left {
        height: 93rem;
        top: -210px;
    }

    .framed-text {
        height: 55.2rem;
        margin-bottom: 19rem;
        p {
            font-size: 2rem;
            color: #ffffff;
        }
        h4 {
            color: #ffffff;
            width: 94rem;
            font-size: 4rem;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    .subscribe-box {
        margin: 0px;
    }
}

.boxed-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: #ffffff;

    .form-button {
        width: 39.6rem;
        height: 5.13rem;
        border-radius: 0px;
        background-color: #162032;
        color: #4ebab8;
        font-size: 2rem;
        margin-bottom: 20px;
    }
}

.form-links-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #162032;
    font-size: 1.6rem;
    margin-top: 0.8rem;
}

.link-label {
    margin-right: 0.3rem;
}

.form-titles {
    width: 100%;
    height: 6.9rem;
    border-top: 2.5px solid #4ebab8;
    .title {
        width: 100%;
        min-width: 22.7rem;
        height: 6.9rem;
        text-align: center;
        color: #4ebab8;
        font-size: 3.3rem;
        font-family: "Sen", sans-serif;
        font-weight: 700;
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding-right: 1rem;
        padding-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.checkbox-wrapper {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.3rem;
}

.form-box {
    width: 45.4rem;
    height: fit-content;
    border-bottom: 2.5px solid #4ebab8;
    border-left: 2.5px solid #4ebab8;
    border-right: 2.5px solid #4ebab8;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3rem;
    z-index: 2;
    background-color: #ffffff;
}

.main-focus {
    width: 100%;
    padding-top: 55px;
    padding-bottom: 17rem;
    font-size: 2rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.registration-text {
    width: 48.98rem;
    margin-right: 5rem;
    z-index: 2;
    p {
        font-size: 2.5rem;
        margin-top: 0px;
        margin-bottom: 25px;
    }
}

.founder-inputs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lower-button {
    margin-top: 1rem;
}

.form-content {
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.form-fieldset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    margin-top: 0px;
}

.fieldset-border {
    border-top: solid 1.5px #4ebab8;
}

.fieldset-title {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-size: 2.8rem;
    font-family: "Sen", sans-serif;
    font-weight: 700;
    color: #4ebab8;
    align-self: flex-start;
}

.character-counter {
    font-size: 1.5rem;
    margin-top: -1.5rem;
    margin-right: 0.5rem;
    align-self: flex-end;
    color: #4ebab8;
}

.image-url-preview {
    width: 30rem;
    height: 30rem;
    padding: 1rem;
    border: 2px solid #4ebab8;
    margin-bottom: 2rem;
}

@media (min-width: 769px) and (max-width: 1360px) {
    .registration {
        .section-header {
            .section-title {
                left: 10%;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .registration {
        .circle {
            width: 45rem;
            height: 25rem;
            left: 23rem;
        }

        .triangle {
            width: 93rem;
        }

        .shape-left {
            top: -13rem;
        }

        .shape-right {
            top: -28rem;
        }

        .framed-text {
            margin-bottom: 9rem;
        }
    }
}

@media (max-width: 768px) {
    .main-focus {
        flex-direction: column;
        align-items: center;
    }

    .form-box {
        margin: 0px;
        background-color: #16203200;
    }

    .form-box.sign-up {
        height: auto;
        margin-bottom: 10rem;
    }

    .form-box.sign-in {
        min-height: unset;
        height: 41rem;
        margin-bottom: 10rem;
    }

    .registration-text {
        width: 65.88rem;
        margin: 0px;
    }

    .registration {
        .framed-text {
            margin-bottom: 10rem;
            h4 {
                width: 60rem;
            }
        }

        .circle {
            width: 38rem;
            height: 18rem;
            bottom: 3rem;
            left: 25rem;
        }

        .triangle {
            width: 95rem;
        }

        .shape-left {
            height: 85rem;
            top: -65px;
        }

        .section-header {
            height: 45rem;
            .section-title {
                width: 70rem;
                font-size: 7rem;
                top: 12%;
                left: 15%;
            }
        }
    }
}
