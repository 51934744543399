.profile {
    background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
    min-height: 1000px;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.company-form {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #ffffff;
}

.profile-form-titles-wrapper {
    width: 100%;
    height: 5rem;
    background-color: #ffffff;
    color: #4ebab8;
    border-bottom: 1.5px solid #162032;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-form-title {
    width: 50%;
    height: 5rem;
    text-align: center;
    font-size: 2.5rem;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-profile-card-grid {
    margin-bottom: 8.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.form-container {
    border: 1.5px solid #162032;
}

.form-active {
    background-color: #162032;
}
