.profile-card {
    width: 42rem;
    height: 40rem;
    background-color: #ffffff;
    border: 1.5px solid #162032;
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-filled {
        width: 18.2rem;
        height: 4rem;
        background-color: #ffffff;
        color: #4ebab8;
        font-weight: lighter;
        font-size: 1.8rem;
        border: 1.5px solid #4ebab8;
        border-radius: 0px;
    }
}

.profile-picture-wrapper {
    width: 16.7rem;
    height: 16.7rem;
    background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
    border-radius: 50%;
    position: relative;
}

.profile-picture-placeholder {
    width: 7.14rem;
    height: 10.29rem;
    position: absolute;
    top: 3.2rem;
    left: 4.8rem;
}

.profile-picture {
    width: 16.7rem;
    height: 16.7rem;
    border-radius: 50%;
    position: relative;
    border: 2px solid #4ebab8;
}

.user-name {
    color: #4ebab8;
    font-size: 2.5rem;
    font-family: "Sen", sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}

.user-email {
    color: #162032;
    font-size: 2rem;
    margin-top: 0px;
    margin-bottom: 3.3rem;
}
