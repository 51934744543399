.introduction {
    width: 100%;
    height: 640px;
    color: #ffffff;

    .button-filled {
        width: 654.01px;
        height: 108.11px;
        background-color: #d1eaf1;
        border-radius: 40px;
        color: #162032;
        font-size: 4rem;
        font-family: "Sen", sans-serif;
        font-weight: 800;
    }

    .section-title {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .triangle {
        width: 108rem;
    }

    .shape-right {
        height: 93rem;
        top: -100px;
    }

    .shape-left {
        height: 95rem;
        top: -180px;
    }
}

.introduction-text {
    width: 100rem;
    height: 18.16rem;
    font-size: 2.5rem;
    margin-top: 43px;
    margin-bottom: 30px;
}

@media (min-width: 769px) and (max-width: 1350px) {
    .introduction {
        height: 63rem;
        .button-filled {
            width: 58rem;
            height: 9.7rem;
            font-size: 3rem;
        }

        .shape-right {
            height: 90rem;
        }

        .triangle {
            width: 100rem;
        }
    }

    .introduction-text {
        width: 78rem;
        height: 27rem;
        margin-bottom: 0;
        font-size: 2.5rem;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .introduction {
        height: 320px;
        .section-title {
            width: 430px;
            margin-bottom: 20px;
        }

        .button-filled {
            width: 297.86px;
            height: 49.24px;
            font-size: 3rem;
            margin-top: 5px;
        }

        .triangle {
            width: 80rem;
        }

        .shape-right {
            height: 85rem;
            right: -40rem;
            top: -37px;
        }

        .shape-left {
            height: 85rem;
            left: -40rem;
            top: -122px;
        }
    }

    .introduction-text {
        width: 69rem;
        height: fit-content;
        font-size: 2rem;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 15px;
    }
}

@media (max-width: 600px) {
    .introduction {
        height: 582.24px;
        .section-title {
            width: 306.79px;
            font-size: 5rem;
            margin-left: 48px;
        }

        .button-filled {
            width: 336px;
            height: 62.16px;
            margin-top: 20px;
        }

        .triangle {
            width: 80rem;
        }

        .shape-right {
            height: 85rem;
            right: -40rem;
            top: 120px;
        }

        .shape-left {
            height: 85rem;
            left: -40rem;
            top: -20px;
        }
    }

    .introduction-text {
        width: 58rem;
        height: fit-content;
        font-size: 3rem;
        margin: 25px;
    }
}
