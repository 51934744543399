.request-form {
    width: 100%;
    height: 1450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .section-title {
        width: 420px;
        color: #ffffff;
        font-size: 7.5rem;
        margin: 0px;
        position: absolute;
        top: 15%;
        left: 25%;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    p {
        width: 75rem;
        height: 7.75rem;
        font-size: 2.5rem;
        text-align: center;
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
}

.form-header {
    color: #4ebab8;
    font-size: 4rem;
    padding-left: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    align-self: flex-start;
}

.claim-form {
    width: 87rem;
    min-height: 61rem;
    height: fit-content;
    border: 3px solid #4ebab8;
    display: flex;
    flex-direction: column;
    align-items: center;

    .submit-button {
        align-self: flex-end;
        margin-bottom: 1rem;
    }
}

.inputs-box {
    display: flex;
    flex-direction: row;

    .main-fieldset {
        width: 39.8rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
    }

    .secondary-fieldset {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

.terms-agreement-label {
    font-size: 1.6rem;
    color: #162032;
}

.policy {
    color: #4ebab8;
}

@media (max-width: 850px) {
    .claim-form {
        .submit-button {
            bottom: -30px;
        }
    }
}
