.welcome-background-image {
    width: 100%;
    height: 80rem;
    position: absolute;
    top: 0;
    opacity: 0.5;
}

.firstfold {
    width: 100%;
    height: 80rem;
    padding: 0px;
    background-color: #162032;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;

    .autocomplete-wrapper {
        position: relative;
    }

    .search-input-wrapper {
        width: 917.36px;
        height: 69.81px;
        background-color: #ffffff;
        border-radius: 26px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
       
    .search-input-wrapper-autocomplete-active {
        border-radius: 26px 26px 0 0;
    }
    
    .search-input {
        outline: none;
        width: 720px;
        height: 69.81px;
        background-color: #ffffff;
        border: 0px;
        border-radius: 26px;
        color: #162032;
        font-size: 1.8rem;
        padding-right: 10px;
        padding-left: 2%;
    }
    
    .search-input::placeholder {
        color: #162032;
    }
    
    .search-input:focus::placeholder {
        color: transparent;
    }


    .autocomplete-items {
        width: 917.36px;
        border: 1px solid #d1eaf1;
        position: absolute;
        border-bottom: none;
        border-top: none;
        z-index: 3;
        top: 86.6%;
        left: 0;
        right: 0;
        padding-left: 0;
        border-radius: 0 0 26px 26px;
    }
    
    .autocomplete-items li {
        color: #162032;
        font-size: 1.8rem;
        text-transform: capitalize;
        padding: 10px 10px 10px 2%;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d1eaf1;
        list-style-type: none;   
    }
    
    .autocomplete-item:first-child {
        border-top: 1px solid #d1eaf1;
    }

    .autocomplete-item:last-child {
        border-radius: 0 0 26px 26px;
        padding-bottom: 12px;
    }
    
    .autocomplete-active, .autocomplete-item:hover {
        background-color: #d1eaf1 !important;
        font-weight: bold;
    }
    
    .button-filled {
        width: 194.22px;
        height: 65.86px;
        background-color: #162032;
        border-radius: 26px;
        color: #d1eaf1;
        font-size: 2rem;
    }
}

.firstfold-content {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        width: 94rem;
        color: #ffffff;
        font-size: 10rem;
        font-family: "Sen", sans-serif;
        font-weight: 800;
        line-height: 120px;
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 6rem;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .firstfold {
        height: 90rem;

        .search-input-wrapper {
            width: 91.7rem;
            height: 7rem;
        }

        .autocomplete-items {
            width: 91.7rem;
        }

        .search-input {
            width: 72rem;
            height: 7rem;
        }

        .button-filled {
            width: 19.4rem;
            height: 6.586rem;
        }
    }

    .firstfold-content {
        h1 {
            width: 85rem;
            font-size: 9rem;
            max-width: 100rem;
            line-height: 12rem;
        }
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .welcome-background-image {
        height: 900px;
    }

    .firstfold {
        height: 900px;

        .search-input-wrapper {
            width: 507.85px;
            height: 54.25px;
        }
        
        .autocomplete-items {
            width: 507.85px;
        }

        .search-input {
            width: 353.85px;
            height: 54.25px;
        }

        .button-filled {
            width: 150.92px;
            height: 50px;
        }
    }

    .firstfold-content {
        h1 {
            width: 75rem;
            line-height: 90px;
        }
    }
}

@media (max-width: 600px) {
    .welcome-background-image {
        height: 800px;
    }

    .firstfold {
        height: 800px;

        .search-input-wrapper {
            width: 402px;
            height: 42.94px;
        }

        .autocomplete-items {
            width: 402px;
        }

        .search-input {
            width: 280px;
            height: 42.94px;
        }

        .button-filled {
            width: 119.46px;
            height: 40px;
        }
    }

    .firstfold-content {
        h1 {
            max-width: 415.72px;
            line-height: 90px;
        }
    }
}