header {
    width: 100%;
    height: 12.6rem;
    background: #162032;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 4;

    .button-filled {
        width: 164.11px;
        height: 65.27px;
        padding: 10px;
        font-size: 2rem;
        line-height: 20px;
        color: #162032;
        font-family: 'Sen', sans-serif;
        font-weight: 700;
        background-color: #4ebab8;
        margin: 1rem;
    }

    .proptechpedia-logotype {
        width: 25rem;
        margin-left: 1.7rem;
        cursor: pointer;
    }
}

.header-action-area {
    margin-right: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .action {
        font-size: 2rem;
        font-family: 'Sen', sans-serif;
        font-weight: 700;
        margin: 1rem;
    }
}

.header-action-drawer-area {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header-action-menu {
    background-color: #162032;
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .action {
        font-size: 3.7rem;
        margin: 10px;
    }
}

.header-action-area,
.header-action-menu {
    .action {
        background-color: #162032;
        color: #d1eaf1;
    }
}

@media (min-width: 769px) and (max-width: 1100px) {
    header {
        .button-filled {
            font-size: 2.7rem;
        }
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    header {
        padding-left: 15px;
        padding-right: 15px;
        .button-filled {
            width: 133.25px;
            height: 53px;
            font-size: 16px;
            padding: 0px;
        }
        .proptechpedia-logotype {
            width: 35rem;
        }
    }
}

@media (max-width: 600px) {
    header {
        .button-filled {
            width: 105.48px;
            height: 41.95px;
            padding: 0px;
            line-height: 15px;
            font-size: 14px;
        }
    }
}