.subscribe_box {
    width: 100%;
    height: 440px;
    background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
    margin-top: 59px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section-title {
        font-size: 2.9rem;
        margin-top: 0px;
        margin-bottom: 20px;
        color: #162032;
        text-align: center;
    }
}

.subscribe-question {
    width: 800px;
    color: #d1eaf1;
    font-size: 2.9rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.subscription-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscription-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subscription-input {
    width: 526px;
    height: 35px;
    background: none;
    color: #d1eaf1;
    font-size: 1.8rem;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 4px solid #162032;
    padding-right: 6px;
    padding-left: 6px;
    padding-bottom: 1.4rem;
}

.subscription-input::placeholder {
    color: #d1eaf1;
}

.subscription-input:focus::placeholder {
    color: transparent;
}

.subscription-input:focus {
    outline: none;
}

.helper-text {
    color: #ff0000;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.subscription-submit {
    width: 526px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #d1eaf1;
    font-size: 2.3rem;
    background-color: #162032;
}

.policy-agreement-label,
.subscription-disclaimer {
    color: #d1eaf1;
    font-size: 1.6rem;
}

.subscription-disclaimer {
    color: #162032;
    margin-top: 2.3rem;
    margin-bottom: 1rem;

}

.policy-agreement-wrapper {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subscription-disclaimer,
.policy-agreement-wrapper {
    width: 526px;
}

.text-link {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    font-weight: bold;
}

@media (min-width: 769px) and (max-width: 1200px) {
    .subscribe-question {
        width: 80rem;
        font-size: 3.5rem;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .subscribe_box {
        height: 330.43px;
        margin-top: 10px;

        .section-title {
            font-size: 3.8rem;
        }
    }

    .subscribe-question {
        width: 554.95px;
        font-size: 3.5rem;
    }

    .subscription-input {
        padding-bottom: 0rem;
    }

    .subscription-submit {
        font-size: 2.5rem;
    }

    .policy-agreement-label,
    .subscription-disclaimer {
        font-size: 1.8rem;
    }

    .helper-text {
        font-size: 1.9rem;
        margin-top: 0.8rem;
    }
}

@media (max-width: 600px) {
    .subscribe_box {
        height: 525px;
        margin-top: 10px;

        .section-title {
            width: 400px;
            font-size: 6.5rem;
            margin-top: 0;
            margin-bottom: 20px;
            text-align: left;
        }
    }

    .subscribe-question {
        width: 390px;
        font-size: 3.5rem;
        text-align: left;
    }

    .subscription-input {
        padding-bottom: 0rem;
    }

    .subscription-disclaimer,
    .policy-agreement-wrapper,
    .subscription-input,
    .subscription-submit {
        width: 400px;
    }

    .subscription-input {
        font-size: 2.3rem;
    }

    .subscription-submit {
        font-size: 2.8rem;
    }

    .policy-agreement-label,
    .subscription-disclaimer {
        font-size: 2rem;
    }

    .helper-text {
        font-size: 2rem;
        margin-top: 0.8rem;
    }
}