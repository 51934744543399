.home-categories {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .categories-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .section-title {
        color: #162032;
        align-self: center;
    }

    .action {
        width: fit-content;
        height: 5.5rem;
        border-radius: 40px;
        background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
        color: #162032;
        font-size: 2.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        margin-top: 4rem;
        align-self: center;
        white-space: nowrap;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .home-categories {
        .categories-box {
            flex-wrap: wrap;
            padding: 0 50px 0 50px;
        }
    }
}

@media (max-width: 600px) {
    .home-categories {
        .categories-box {
            flex-wrap: wrap;
        }
    }
}
