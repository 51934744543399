.cookies-banner {
    border: solid 5px #4ebab8;
    background-color: #fff;
	padding: 20px;
	width: calc(100% - 40px);
	max-width: 300px;
	line-height: 1.8rem;
	border-radius: 10px;
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 4;
    color: #162032;
	text-align: center;
}

.cookies-banner.hide {
    animation: hide_banner 1s ease forwards;
}
@keyframes hide_banner {
    0%{
        transform: translateY(-10px);
    }
    40%{
        transform: translateY(0%);
    }
    80%{
        transform: translateY(-10%);
    }
    100%{
        transform: translateY(120%);
    }
}

.cookies-banner.active {
	display: block;
    animation: show_banner 1s ease forwards;
}
@keyframes show_banner {
    0%{
        transform: translateY(100%);
    }
    40%{
        transform: translateY(-10%);
    }
    80%{
        transform: translateY(0%);
    }
    100%{
        transform: translateY(-10px);
    }
}

.cookies-banner {
    .action-button {
        width: 100%;
        background: #162032;
        border: none;
        color: #fff;
        font-family: 'Sen', sans-serif;
        text-align: center;
        padding: 1.5rem 2rem;
        font-weight: 700;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
    }

    .action-button:hover {
        background: #4ebab8;
    }
}

.cookies-banner-title {
    font-size: 3rem;
    color: #4ebab8;
    font-family: 'Sen', sans-serif;
    font-weight: 700;
    line-height: 2.8rem;
}

.cookes-banner-text {
    font-size: 1.8rem;
}


.cookies-banner .titulo,
.cookies-banner .parrafo {
	margin-bottom: 15px;
}