.not-found {
    height: 130rem;
    font-family: "Sen", sans-serif;
    font-weight: 800;
    background: linear-gradient(90deg, #4a86c6 14.34%, #7ac6c5 73.45%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .action-button {
        color: #162032;
        background-color: transparent;
        font-size: 5rem;
        font-family: "Sen", sans-serif;
        font-weight: 800;
        margin-bottom: 1.5rem;
    }

    .message {
        width: 105rem;
        color: #ffffff;
        text-align: center;
        font-size: 5rem;
        margin-bottom: 50px;
    }

    p {
        margin: 10px;
    }

    .top {
        font-size: 20rem;
        margin-top: 80px;
        margin-bottom: -5rem;
    }

    .bottom {
        font-size: 13rem;
        margin-top: 0px;
    }
}

.error-404 {
    color: #162032;
}
