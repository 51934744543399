.company-grid {
    border-top: 1px solid #162032;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.featured-company-container {
    width: 20rem;
    height: 20.76rem;
    color: #162032;
    font-size: 4rem;
    margin-bottom: 12.5px;
    margin-top: 12.5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .company-logo {
        width: 17.6rem;
        height: 17.6rem;
        margin-right: 1.5rem;
        background-color: #162032;
    }

    .button-filled {
        width: 23rem;
        height: 4rem;
        font-family: "Sen";
        font-weight: 100;
        font-size: 1.5rem;
        border-radius: 30px;
        color: #ffffff;
        margin-top: 1rem;
    }
}

.featured-company-category {
    background-color: #162032;
}

.featured-company-subcategory {
    background-color: #4ebab8;
}

.featured-company-info {
    width: fit-content;
    height: 17.25rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin: 0 0 0 1rem;
    }
}

@media (min-width: 769px) and (max-width: 1385px) {
    .featured-company-container {
        width: 31.71rem;
        height: 17rem;
        font-size: 2.8rem;

        .company-logo {
            margin-right: 0px;
            width: 15rem;
            height: 15rem;
        }

        .button-filled {
            font-size: 1.48rem;
            width: 19rem;
            height: 3.5rem;
        }
    }
}

@media (max-width: 768px) {
    .featured-company-info {
        width: fit-content;
        height: 14.5rem;
    }
}
